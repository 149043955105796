import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HeroBannerSliderBlock as HeroBannerSliderBlockData } from '../../../graphql/generated/api-graphql';
import { BlockProps } from '../props';
import HeroSlider from '../../../components/molecules/HeroSlider';
import heroBannerSliderBlockTransform from './transform';
export type HeroBannerSliderBlockProps = Omit<BlockProps, 'data'> & {
  data: HeroBannerSliderBlockData;
};
const HeroBannerSliderBlock: FC<HeroBannerSliderBlockProps> = ({
  data,
  index
}) => {
  const {
    t
  } = useTranslation();
  const heroProps = heroBannerSliderBlockTransform(t, data, index < 2);
  return <HeroSlider {...heroProps} />;
};
export default HeroBannerSliderBlock;