import React, { FC, useMemo, useState } from 'react';
import { Navigation, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { PictureProps } from '../../atoms/Picture';
import { LinkProps } from '../../atoms/Link';
import * as SC from './styled';
export type HeroSliderSlideProps = {
  imageProps?: PictureProps;
  imageMobileProps?: PictureProps;
  title?: string;
  subtitle?: string;
  text?: string;
  linkProps?: LinkProps;
  buttonLabel?: string;
  isFullLink?: boolean;
};
export type HeroSliderProps = MinimalSeoBlock & {
  className?: string;
  slides: HeroSliderSlideProps[];
};
const HeroSlider: FC<HeroSliderProps> = ({
  className,
  slides,
  htmlTag,
  htmlTitleTag
}) => {
  const [slide, setSlide] = useState(slides?.[0]);
  const handleSlideChange = (sw: SwiperType) => {
    setSlide(slides[sw.realIndex]);
  };
  const hasSlides = slides?.length > 1;
  const renderSlides = useMemo(() => slides?.map((entry, i) => <SwiperSlide key={i}>
          <SC.DesktopImage {...entry?.imageProps} />
          <SC.MobileImage {...entry?.imageMobileProps} />
        </SwiperSlide>), [slides]);
  return <SC.Slider className={className} as={htmlTag}>
      <SC.SwiperContainer>
        {hasSlides ? <Swiper threshold={5} loop onSlideChange={handleSlideChange} modules={[Navigation]} navigation>
            {renderSlides}
          </Swiper> : renderSlides}
      </SC.SwiperContainer>
      <SC.CardLink {...slide?.linkProps}>
        <SC.Bezel viewBox="0 0 335 11" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <path d="M0 0.375V10.375H168.303C99.7883 10.3705 38.8536 6.45373 0 0.375ZM168.537 10.375H335V0.658797C296.078 6.57536 235.98 10.3706 168.537 10.375Z" />
        </SC.Bezel>
        <SC.Content>
          {slide?.subtitle && <SC.Subtitle>{slide?.subtitle}</SC.Subtitle>}
          {slide?.title && <SC.Title as={htmlTitleTag}>{slide?.title}</SC.Title>}
          {slide?.text && <SC.Text dangerouslySetInnerHTML={{
          __html: slide?.text ?? ''
        }} />}
          {slide?.linkProps && slide?.buttonLabel && <SC.Button>{slide.buttonLabel}</SC.Button>}
        </SC.Content>
      </SC.CardLink>
    </SC.Slider>;
};
export default HeroSlider;